import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="contact-address">
        <h2>Kontakt & Anfahrt</h2>
        <p>Haus Shiraz</p>
        <p>Meineckestrasse 52b</p>
        <p>40474 Düsseldorf</p>
      </div>
      <div className="contact-details">
        <p>
          <a href="tel:021165858630">0211 658 586 30</a>
        </p>
        <p>Whatsapp</p>
        <p>info@haus-shiraz.de</p>
      </div>
      <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2497.140703100357!2d6.767702976656654!3d51.253318671756624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8c96b4ef00d31%3A0xb7f4f2d5f395150!2sHaus%20Shiraz!5e0!3m2!1snl!2snl!4v1717180789307!5m2!1snl!2snl"
          width="auto"
          height="200"
          style={{ border: 0, borderRadius: "10px" }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Footer;
