import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Logo from "../images/logo-haus-shiraz.svg";
import instagram from "../Icons/instagram.svg";
import WhatsApp from "../Icons/Whatsapp.svg";
import call from "../Icons/call.svg";

function Header({ title, backgroundImage }) {
  const headerStyle = {
    display: "flex",
    maxWidth: "1340px",
    margin: "0 auto",
    justifyContent: "space-between",
    color: "white",
    padding: "15px",
    alignItems: "center"
  };

  return (
    <header>
      <div style={headerStyle}>
        <Link to="/">
          <img src={Logo} alt="Logo" className="header-logo" />
        </Link>

        <div className="social-icons">
          <a
            href="https://www.instagram.com/haus.shiraz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="Instagram" />
          </a>
          <a
            href="https://wa.me/4921165858630"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={WhatsApp} alt="WhatsApp" />
          </a>
          <a href="tel:004921165858630" className="call-icon-home">
            <span>Call us</span>
            <img src={call} alt="Call Us" />
          </a>
        </div>
      </div>
      {title && (
        <h1
          className="header-title"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          {title}
        </h1>
      )}
    </header>
  );
}

export default Header;
