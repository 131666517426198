import React from "react";

import "./SecondaryHeader.css";
import { Link } from "react-router-dom";
import Logo from "../images/logo-haus-shiraz-white.svg";
import instagram from "../Icons/instagram-white.svg";
import WhatsApp from "../Icons/Whatsapp-white.svg";
import call from "../Icons/call.svg";

function SecondaryHeader({ backgroundImage }) {
  const headerStyle = {};

  const overlayStyle = {
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "space-between",
    color: "white",
    padding: "15px"
  };

  return (
    <header>
      <div style={headerStyle}>
        <div style={overlayStyle}>
          <div className="inside-style">
            <Link to="/">
              <img src={Logo} alt="Logo" className="secondary-header-logo" />
            </Link>
            <div className="social-icons-home">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="instagram-icon"
              >
                <img src={instagram} alt="Instagram" />
              </a>
              <a
                href="https://whatsapp.com"
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-icon"
              >
                <img src={WhatsApp} alt="WhatsApp" />
              </a>
              <a href="tel:+1234567890" className="call-icon">
                <img src={call} alt="Call Us" />
                <span>Call</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default SecondaryHeader;
