import React from "react";
import { Link } from "react-router-dom";
import SecondaryHeader from "../../SecondaryHeader.js/SecondaryHeader";

import "./Tagung.css";

import celebrate1 from "../../images/celebrate.png";
import celebrate2 from "../../images/celebrate1.png";
import celebrate3 from "../../images/celebrate2.png";
import celebrate4 from "../../images/celebrate3.png";
import celebrate5 from "../../images/celebrate4.png";
import celebrate6 from "../../images/celebrate5.png";

function Funeral() {
  const images = [
    celebrate1,
    celebrate2,
    celebrate3,
    celebrate4,
    celebrate5,
    celebrate6
  ];
  const doubleImages = [...images, ...images];
  return (
    <>
      <SecondaryHeader />
      <div className="tagung-hero-section">
        <div className="tagung-hero-content">
          <div className="tagung-hero-breadcrumb">
            <Link to="/">Home</Link> {">"} Versammlung und Seminar
          </div>
          <div className="tagung-hero-title">
            <h1>Versammlung und Seminar</h1>
          </div>
          <div className="tagung-hero-button">
            <Link to="/">Anfragen →</Link>
          </div>
        </div>
      </div>
      <div className="tagung-container">
        <div className="tagung-page">
          <div className="tagung-hero-text">
            <h2>
              Reservieren Sie Ihre Versammlung oder Ihr Seminar bei uns – Der
              perfekte Ort für Ihre Veranstaltung
            </h2>
            <p>
              Planen Sie eine Versammlung, ein Seminar oder eine Tagung? Unser
              Veranstaltungssaal bietet die ideale Umgebung für Ihre
              geschäftlichen und professionellen Treffen. Mit modernster
              Ausstattung, flexiblen Raumgestaltungsmöglichkeiten und
              erstklassigem Service sorgen wir dafür, dass Ihre Veranstaltung
              ein voller Erfolg wird. Reservieren Sie jetzt und sichern Sie sich
              Ihren Wunschtermin!
            </p>
            <h3>Ihre Versammlung oder Ihr Seminar bei uns:</h3>
            <ul>
              <li>
                Moderne Ausstattung: Nutzen Sie unsere hochwertige Audio- und
                Videotechnik für Präsentationen, Diskussionen und Workshops.
              </li>
              <li>
                Flexible Raumgestaltung: Passen Sie die Bestuhlung und
                Raumaufteilung genau an Ihre Bedürfnisse an.
              </li>
              <li>
                Erstklassiger Service: Unser erfahrenes Team unterstützt Sie bei
                der Planung und Durchführung Ihrer Veranstaltung.
              </li>
              <li>
                Catering-Optionen: Bieten Sie Ihren Teilnehmern leckere Snacks
                und Getränke oder ein vollwertiges Catering.
              </li>
            </ul>
            <h3>Warum unser Veranstaltungssaal?</h3>
            <ul>
              <li>
                Professionelle Atmosphäre: Schaffen Sie den idealen Rahmen für
                produktive und erfolgreiche Meetings.
              </li>
              <li>
                Zentrale Lage: Unser Saal ist leicht erreichbar und bietet
                ausreichend Parkmöglichkeiten.
              </li>
              <li>
                Komplette Betreuung: Von der Planung bis zur Durchführung – wir
                sind an Ihrer Seite.
              </li>
              <li>
                Individuelle Lösungen: Wir gehen auf Ihre spezifischen
                Anforderungen ein und bieten maßgeschneiderte Lösungen.
              </li>
            </ul>
            <h3>So einfach reservieren Sie Ihre Veranstaltung:</h3>
            <ol>
              <li>
                Kontaktaufnahme: Füllen Sie den Online-Formular aus,rufen Sie
                uns an oder schreiben Sie uns eine E-Mail, um Ihre Anfrage zu
                stellen.
              </li>
              <li>
                Beratung und Besichtigung: Vereinbaren Sie einen Termin für ein
                persönliches Beratungsgespräch und eine Besichtigung unseres
                Saals.
              </li>
              <li>
                Individuelle Planung: Teilen Sie uns Ihre Wünsche mit und wir
                erstellen gemeinsam ein maßgeschneidertes Konzept für Ihre
                Feier.
              </li>
              <li>
                Angebot und Bestätigung: Erhalten Sie ein detailliertes Angebot
                und bestätigen Sie Ihre Reservierung durch Unterzeichnung des
                Vertrags.
              </li>
              <li>
                Eventvorbereitung: Lassen Sie uns die Details übernehmen,
                während Sie sich auf Ihre Feier freuen können.
              </li>
            </ol>

            <p>
              Lassen Sie uns gemeinsam Ihre Versammlung oder Ihr Seminar zu
              einem erfolgreichen und unvergesslichen Ereignis machen.
              Kontaktieren Sie uns noch heute und sichern Sie sich den perfekten
              Ort für Ihre Veranstaltung!
            </p>
            <p>
              Dieser Text lädt Ihre Gäste zur Reservierung ihrer Versammlungen
              und Seminare ein und hebt die besonderen Vorteile und den
              einfachen Reservierungsprozess hervor. Passen Sie den Text gerne
              weiter an Ihre spezifischen Angebote und Bedürfnisse an.
            </p>
          </div>
          <div className="image-slider">
            {doubleImages.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Slide ${index + 1}`}
                className="slide-image"
              />
            ))}
          </div>
          <div className="tagung-anfragen-section">
            <h1>Anfragen</h1>
            <iframe
              src="https://www.cognitoforms.com/f/jNdX0G9liUemtCABkK626g/10"
              width="100%"
              height="693"
              border="0"
              class="styled-iframe"
            ></iframe>
            <script src="https://www.cognitoforms.com/f/iframe.js"></script>
          </div>
        </div>
      </div>
    </>
  );
}

export default Funeral;
