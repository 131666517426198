import React from "react";
import Header from "../Header/Header";
import celebrate from "../images/celebrate.png";
import image2 from "../images/image2.png";
import image3 from "../images/image3.png";
import image4 from "../images/image4.png";
import image5 from "../images/image5.png";
import image6 from "../images/image6.png";

import { Link } from "react-router-dom";
import arrow from "../images/arrow.svg";
import "./MainPage.css";

function MainPage() {
  const boxInfos = [
    {
      title: "Events und Partys",
      image: celebrate,
      link: "/celebrate",
      iconLink: "/celebrate"
    },
    {
      title: "Trauerfeier",
      image: image2,
      link: "/funeral",
      iconLink: "/funeral"
    },
    {
      title: "Hochzeit und Geburtstag",
      image: image3,
      link: "/hochzeit",
      iconLink: "/hochzeit"
    },
    {
      title: "Workshop",
      image: image4,
      link: "/workshop",
      iconLink: "/workshop"
    },
    {
      title: "Versammlung und Seminar",
      image: image5,
      link: "/tagung",
      iconLink: "/tagung"
    },
    {
      title: "Sonstiges",
      image: image6,
      link: "/sonstiges",
      iconLink: "/sonstiges"
    }
  ];

  return (
    <div>
      <Header />
      <div className="main-section">
        {boxInfos.map((info, index) => (
          <div
            className="image-box"
            style={{ backgroundImage: `url(${info.image})` }}
            key={index}
          >
            <div className="image-box-content">
              <h2>{info.title}</h2>
              {info.iconLink && (
                <Link to={info.iconLink} className="image-box-icon">
                  <img src={arrow} alt="Arrow" />
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="cta">Für alle Ihre Veranstaltungen in Düsseldorf</div>
    </div>
  );
}

export default MainPage;
