import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SecondaryHeader from "../../../SecondaryHeader.js/SecondaryHeader";
import "./Celebrate.css";

import celebrate1 from "../../../images/celebrate.png";
import celebrate2 from "../../../images/celebrate1.png";
import celebrate3 from "../../../images/celebrate2.png";
import celebrate4 from "../../../images/celebrate3.png";
import celebrate5 from "../../../images/celebrate4.png";
import celebrate6 from "../../../images/celebrate5.png";

function Celebrate() {
  useEffect(() => {
    const scriptId = "eventix-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://shop.eventix.io/build/integrate.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const images = [
    celebrate1,
    celebrate2,
    celebrate3,
    celebrate4,
    celebrate5,
    celebrate6
  ];
  const doubleImages = [...images, ...images];

  return (
    <>
      <SecondaryHeader />
      <div className="hero-section">
        <div className="hero-content">
          <div className="hero-breadcrumb">
            <Link to="/">Home</Link> {">"} Events und Partys
          </div>
          <div className="hero-title">
            <h1>Events und Partys</h1>
          </div>
          <div className="hero-button">
            <Link to="/">Anfragen →</Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="events-list">
          <div
            id="shop-frame"
            data-url="https://shop.eventix.io/d917e023-2002-11ef-a9cb-7e126431635e"
            style={{
              maxWidth: "100%",
              margin: "0 auto",
              borderRadius: "20px"
            }}
          ></div>
        </div>
        <div className="content">
          <h1>
            Feiern Sie mit uns – Sichern Sie sich jetzt Ihren Platz für die
            Party des Jahres!
          </h1>
          <p>
            Wir haben etwas ganz Besonderes für Sie geplant! Unsere exklusive
            Events und Partys stehen vor der Tür und Sie sind herzlich
            eingeladen, dabei zu sein. Erleben Sie einen unvergesslichen Zeit
            und großartiger Unterhaltung in einer einzigartigen Atmosphäre. Aber
            seien Sie schnell – die Plätze sind begrenzt und die Nachfrage ist
            groß!
          </p>
        </div>
        <div className="image-slider">
          {doubleImages.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Slide ${index + 1}`}
              className="slide-image"
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Celebrate;
