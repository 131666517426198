// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import MainPage from "./Main/MainPage";
import Footer from "./Footer/Footer";
import "./App.css";
import Celebrate from "./Components/Event/Celebrate/Celebrate";
import Funeral from "./Pages/Funeral/Funeral";
import Hochzeit from "./Pages/Hochzeit/Hochzeit";
import Workshop from "./Pages/Workshop/Workshop";
import Tagung from "./Pages/Tagung/Tagung";
import Sonstiges from "./Pages/Sonstiges/Sonstiges";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/celebrate" element={<Celebrate />} />
        <Route path="/funeral" element={<Funeral />} />
        <Route path="/hochzeit" element={<Hochzeit />} />
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/tagung" element={<Tagung />} />
        <Route path="/sonstiges" element={<Sonstiges />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
