import React from "react";
import { Link } from "react-router-dom";
import SecondaryHeader from "../../SecondaryHeader.js/SecondaryHeader";

import "./Hochzeit.css";

import celebrate1 from "../../images/celebrate.png";
import celebrate2 from "../../images/celebrate1.png";
import celebrate3 from "../../images/celebrate2.png";
import celebrate4 from "../../images/celebrate3.png";
import celebrate5 from "../../images/celebrate4.png";
import celebrate6 from "../../images/celebrate5.png";

function Funeral() {
  const images = [
    celebrate1,
    celebrate2,
    celebrate3,
    celebrate4,
    celebrate5,
    celebrate6
  ];
  const doubleImages = [...images, ...images];
  return (
    <>
      <SecondaryHeader />
      <div className="hochzeit-hero-section">
        <div className="hochzeit-hero-content">
          <div className="hochzeit-hero-breadcrumb">
            <Link to="/">Home</Link> {">"} Hochzeit und Geburtstag
          </div>
          <div className="hochzeit-hero-title">
            <h1>Hochzeit und Geburtstag</h1>
          </div>
          <div className="hochzeit-hero-button">
            <Link to="/">Anfragen →</Link>
          </div>
        </div>
      </div>
      <div className="hochzeit-container">
        <div className="hochzeit-page">
          <div className="hochzeit-hero-text">
            <h2>
              Reservieren Sie Ihre Traumhochzeit oder unvergessliche
              Geburtstagsfeier bei uns!
            </h2>
            <p>
              Planen Sie eine besondere Feier, die Sie und Ihre Gäste für immer
              in Erinnerung behalten werden? Unser Veranstaltungssaal bietet den
              perfekten Rahmen für Ihre Traumhochzeit oder eine unvergessliche
              Geburtstagsfeier. Mit unserer langjährigen Erfahrung und unserem
              erstklassigen Service sorgen wir dafür, dass Ihr besonderer Tag zu
              einem außergewöhnlichen Erlebnis wird. Reservieren Sie jetzt und
              sichern Sie sich Ihren Wunschtermin!
            </p>
            <h3>Ihre Traumhochzeit</h3>
            <p>
              Feiern Sie den schönsten Tag Ihres Lebens in einem stilvollen und
              eleganten Ambiente. Unser Veranstaltungssaal bietet alles, was Sie
              für eine perfekte Hochzeit brauchen:
            </p>
            <ul>
              <li>
                Romantische Dekoration: Lassen Sie sich von unserer liebevollen
                und individuellen Dekoration verzaubern.
              </li>
              <li>
                Exquisites Catering: Genießen Sie kulinarische Highlights, die
                Ihre Gäste begeistern werden.
              </li>
              <li>
                Hochwertige Technik: Nutzen Sie unsere moderne Audio- und
                Videotechnik für Reden, Musik und Präsentationen.
              </li>
              <li>
                Erfahrener Service: Unser engagiertes Team steht Ihnen von der
                Planung bis zur Durchführung zur Seite.
              </li>
            </ul>

            <h3>Ihre unvergessliche Geburtstagsfeier</h3>
            <p>
              Egal ob runder Geburtstag oder ein besonderer Jahrestag – wir
              gestalten Ihre Feier ganz nach Ihren Wünschen:
            </p>
            <ul>
              <li>
                Festliche Atmosphäre: Feiern Sie in einem stilvollen und
                festlichen Ambiente.
              </li>
              <li>
                Individuelle Gestaltung: Wir passen Dekoration und
                Raumgestaltung genau an Ihre Vorstellungen an.
              </li>
              <li>
                Vielfältige Catering-Optionen: Von kreativen Buffets bis hin zu
                exklusiven Menüs – für jeden Geschmack ist etwas dabei.
              </li>
              <li>
                Unterhaltung und Musik: Buchen Sie Live-Musik oder einen DJ, um
                Ihre Gäste zu begeistern.
              </li>
            </ul>

            <h3>So einfach reservieren Sie Ihre Feier</h3>
            <ol>
              <li>
                Kontaktaufnahme: Füllen Sie den Online-Formular an,rufen Sie uns
                an oder schreiben Sie uns eine E-Mail, um Ihre Anfrage zu
                stellen.
              </li>
              <li>
                Beratung und Besichtigung: Vereinbaren Sie einen Termin für ein
                persönliches Beratungsgespräch und eine Besichtigung unseres
                Saals.
              </li>
              <li>
                Individuelle Planung: Teilen Sie uns Ihre Wünsche mit und wir
                erstellen gemeinsam ein maßgeschneidertes Konzept für Ihre
                Feier.
              </li>
              <li>
                Angebot und Bestätigung: Erhalten Sie ein detailliertes Angebot
                und bestätigen Sie Ihre Reservierung durch Unterzeichnung des
                Vertrags.
              </li>
              <li>
                Eventvorbereitung: Lassen Sie uns die Details übernehmen,
                während Sie sich auf Ihre Feier freuen können.
              </li>
            </ol>

            <h3>
              Reservieren Sie jetzt und sichern Sie sich Ihren Wunschtermin
            </h3>
            <p>
              Zögern Sie nicht länger und sichern Sie sich den perfekten Ort für
              Ihre Traumhochzeit oder unvergessliche Geburtstagsfeier.
              Kontaktieren Sie uns noch heute und lassen Sie uns gemeinsam Ihren
              besonderen Tag unvergesslich machen!
            </p>
            <p>
              Dieser Text lädt Ihre Gäste zur Reservierung ihrer Hochzeiten und
              Geburtstagsfeiern ein und hebt die besonderen Vorteile und den
              einfachen Reservierungsprozess hervor. Passen Sie den Text gerne
              weiter an Ihre spezifischen Angebote und Bedürfnisse an.
            </p>
          </div>
          <div className="image-slider">
            {doubleImages.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Slide ${index + 1}`}
                className="slide-image"
              />
            ))}
          </div>
          <div className="hochzeit-anfragen-section">
            <h1>Anfragen</h1>
            <iframe
              src="https://www.cognitoforms.com/f/jNdX0G9liUemtCABkK626g/10"
              width="100%"
              height="693"
              border="0"
              class="styled-iframe"
            ></iframe>
            <script src="https://www.cognitoforms.com/f/iframe.js"></script>
          </div>
        </div>
      </div>
    </>
  );
}

export default Funeral;
