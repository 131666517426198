import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SecondaryHeader from "../../SecondaryHeader.js/SecondaryHeader";

import "./Workshop.css";

import celebrate1 from "../../images/celebrate.png";
import celebrate2 from "../../images/celebrate1.png";
import celebrate3 from "../../images/celebrate2.png";
import celebrate4 from "../../images/celebrate3.png";
import celebrate5 from "../../images/celebrate4.png";
import celebrate6 from "../../images/celebrate5.png";

function Funeral() {
  useEffect(() => {
    const scriptId = "eventix-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://shop.eventix.io/build/integrate.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const images = [
    celebrate1,
    celebrate2,
    celebrate3,
    celebrate4,
    celebrate5,
    celebrate6
  ];
  const doubleImages = [...images, ...images];
  return (
    <>
      <SecondaryHeader />
      <div className="workshop-hero-section">
        <div className="workshop-hero-content">
          <div className="workshop-hero-breadcrumb">
            <Link to="/">Home</Link> {">"} Workshop
          </div>
          <div className="workshop-hero-title">
            <h1>Workshop</h1>
          </div>
          <div className="workshop-hero-button">
            <Link to="/">Anfragen →</Link>
          </div>
        </div>
      </div>
      <div className="workshop-container">
        <div className="workshop-page">
          <div className="workshop-hero-text">
            <h2>
              Entdecken Sie unsere spannenden Workshops – Jetzt Plätze sichern!
            </h2>
            <div className="events-list">
              <div
                id="shop-frame"
                data-url="https://shop.eventix.io/1830c04d-0c28-4df3-940f-a270eac0a5a8"
                style={{
                  maxWidth: "100%",
                  margin: "0 auto",
                  borderRadius: "20px"
                }}
              ></div>
            </div>
            <p>
              Sie möchten Neues lernen, Ihre Fähigkeiten erweitern oder einfach
              eine inspirierende Zeit verbringen? Dann sind unsere Workshops
              genau das Richtige für Sie! Wir bieten eine Vielzahl von Workshops
              zu unterschiedlichen Themen, die Ihnen einzigartige Erfahrungen
              und wertvolle Kenntnisse bieten. Aber seien Sie schnell – die
              Plätze sind begrenzt und die Nachfrage ist groß!
            </p>
            <h3>Warum Sie unsere Workshops besuchen sollten:</h3>
            <ul>
              <li>
                Erfahrene Trainer: Lernen Sie von Experten, die ihre
                Leidenschaft und ihr Wissen mit Ihnen teilen.
              </li>
              <li>
                Praktische Erfahrungen: Profitieren Sie von hands-on Übungen und
                praxisnahen Anwendungen.
              </li>
              <li>
                Inspirierendes Umfeld: Treffen Sie gleichgesinnte Menschen und
                erweitern Sie Ihr Netzwerk.
              </li>
              <li>
                Vielfältige Themen: Egal ob kreative Künste, persönliche
                Entwicklung oder berufliche Fähigkeiten – bei uns finden Sie den
                passenden Workshop.
              </li>
            </ul>
            <h3>
              Warten Sie nicht länger – die Plätze sind begrenzt und die
              Nachfrage ist groß!
            </h3>
            <p>
              Melden Sie sich noch heute an und sichern Sie sich Ihren Platz in
              einem unserer spannenden Workshops. Wir freuen uns darauf, mit
              Ihnen gemeinsam zu lernen und zu wachsen!
            </p>
            <p>
              Dieser Text ist darauf ausgelegt, die Dringlichkeit der
              Reservierung zu betonen und die Vorteile der Teilnahme an Ihren
              Workshops hervorzuheben. Sie können den Text gerne weiter an Ihre
              spezifischen Angebote und Besonderheiten anpassen.
            </p>
          </div>
          <div className="image-slider">
            {doubleImages.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Slide ${index + 1}`}
                className="slide-image"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Funeral;
