import React from "react";
import { Link } from "react-router-dom";
import SecondaryHeader from "../../SecondaryHeader.js/SecondaryHeader";

import "./Sonstiges.css";

import celebrate1 from "../../images/celebrate.png";
import celebrate2 from "../../images/celebrate1.png";
import celebrate3 from "../../images/celebrate2.png";
import celebrate4 from "../../images/celebrate3.png";
import celebrate5 from "../../images/celebrate4.png";
import celebrate6 from "../../images/celebrate5.png";

function Funeral() {
  const images = [
    celebrate1,
    celebrate2,
    celebrate3,
    celebrate4,
    celebrate5,
    celebrate6
  ];
  const doubleImages = [...images, ...images];
  return (
    <>
      <SecondaryHeader />
      <div className="sonstiges-hero-section">
        <div className="sonstiges-hero-content">
          <div className="sonstiges-hero-breadcrumb">
            <Link to="/">Home</Link> {">"} Sonstiges
          </div>
          <div className="sonstiges-hero-title">
            <h1>Sonstiges</h1>
          </div>
          <div className="sonstiges-hero-button">
            <Link to="/">Anfragen →</Link>
          </div>
        </div>
      </div>
      <div className="sonstiges-container">
        <div className="sonstiges-page">
          <div className="sonstiges-hero-text">
            <h2>
              Reservieren Sie Ihre Veranstaltung bei uns – Für jeden Anlass der
              perfekte Rahmen
            </h2>
            <p>
              Sie planen eine besondere Veranstaltung, die nicht in die
              klassischen Kategorien passt? Kein Problem! Unser
              Veranstaltungssaal bietet den idealen Rahmen für eine Vielzahl von
              Anlässen. Ob Vereinsfeiern, kulturelle Events, Ausstellungen,
              Wohltätigkeitsveranstaltungen oder jede andere Art von Feier – bei
              uns sind Sie richtig. Reservieren Sie jetzt und sichern Sie sich
              Ihren Wunschtermin!
            </p>
            <h3>Ihre Veranstaltung bei uns:</h3>
            <ul>
              <li>
                Vielfältige Nutzungsmöglichkeiten: Unser Saal kann flexibel an
                Ihre Bedürfnisse angepasst werden – von kleinen Treffen bis hin
                zu großen Events.
              </li>
              <li>
                Moderne Ausstattung: Nutzen Sie unsere hochwertige Audio- und
                Videotechnik, um Ihre Veranstaltung perfekt in Szene zu setzen.
              </li>
              <li>
                Individuelle Raumgestaltung: Passen Sie die Bestuhlung und
                Dekoration genau an das Thema Ihrer Veranstaltung an.
              </li>
              <li>
                Professioneller Service: Unser erfahrenes Team unterstützt Sie
                bei der Planung und Durchführung Ihrer Veranstaltung.
              </li>
            </ul>

            <h3>Warum unser Veranstaltungssaal?</h3>
            <ul>
              <li>
                Stilvolle Atmosphäre: Schaffen Sie den idealen Rahmen für Ihre
                Veranstaltung in einem eleganten Ambiente.
              </li>
              <li>
                Zentrale Lage: Unser Saal ist leicht erreichbar und bietet
                ausreichend Parkmöglichkeiten.
              </li>
              <li>
                Komplette Betreuung: Von der ersten Idee bis zur Umsetzung – wir
                sind an Ihrer Seite.
              </li>
              <li>
                Maßgeschneiderte Lösungen: Wir gehen auf Ihre spezifischen
                Anforderungen ein und bieten individuelle Lösungen.
              </li>
            </ul>

            <h3>So einfach reservieren Sie Ihre Veranstaltung:</h3>
            <ol>
              <li>
                Kontaktaufnahme: Füllen Sie den Online-Formular aus,rufen Sie
                uns an oder schreiben Sie uns eine E-Mail, um Ihre Anfrage zu
                stellen.
              </li>
              <li>
                Beratung und Besichtigung: Vereinbaren Sie einen Termin für ein
                persönliches Beratungsgespräch und eine Besichtigung unseres
                Saals.
              </li>
              <li>
                Individuelle Planung: Teilen Sie uns Ihre Wünsche mit und wir
                erstellen gemeinsam ein maßgeschneidertes Konzept für Ihre
                Feier.
              </li>
              <li>
                Angebot und Bestätigung: Erhalten Sie ein detailliertes Angebot
                und bestätigen Sie Ihre Reservierung durch Unterzeichnung des
                Vertrags.
              </li>
              <li>
                Eventvorbereitung: Lassen Sie uns die Details übernehmen,
                während Sie sich auf Ihre Feier freuen können.
              </li>
            </ol>
            <p>
              Lassen Sie uns gemeinsam Ihre Veranstaltung zu einem erfolgreichen
              und unvergesslichen Ereignis machen. Kontaktieren Sie uns noch
              heute und sichern Sie sich den perfekten Ort für Ihre Feier!
            </p>

            <p>
              Dieser Text lädt Ihre Gäste zur Reservierung verschiedenster
              Veranstaltungen ein und hebt die besonderen Vorteile und den
              einfachen Reservierungsprozess hervor. Passen Sie den Text gerne
              weiter an Ihre spezifischen Angebote und Bedürfnisse an.
            </p>
          </div>
          <div className="image-slider">
            {doubleImages.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Slide ${index + 1}`}
                className="slide-image"
              />
            ))}
          </div>

          <div className="sonstiges-anfragen-section">
            <h1>Anfragen</h1>
            <iframe
              src="https://www.cognitoforms.com/f/jNdX0G9liUemtCABkK626g/10"
              width="100%"
              height="693"
              border="0"
              class="styled-iframe"
            ></iframe>
            <script src="https://www.cognitoforms.com/f/iframe.js"></script>
          </div>
        </div>
      </div>
    </>
  );
}

export default Funeral;
