// Funeral.js
import React from "react";
import { Link } from "react-router-dom";
import SecondaryHeader from "../../SecondaryHeader.js/SecondaryHeader";

import "./Funeral.css";

import celebrate1 from "../../images/celebrate.png";
import celebrate2 from "../../images/celebrate1.png";
import celebrate3 from "../../images/celebrate2.png";
import celebrate4 from "../../images/celebrate3.png";
import celebrate5 from "../../images/celebrate4.png";
import celebrate6 from "../../images/celebrate5.png";

function Funeral() {
  const images = [
    celebrate1,
    celebrate2,
    celebrate3,
    celebrate4,
    celebrate5,
    celebrate6
  ];
  const doubleImages = [...images, ...images];
  return (
    <>
      <SecondaryHeader />
      <div className="funeral-hero-section">
        <div className="funeral-hero-content">
          <div className="funeral-hero-breadcrumb">
            <Link to="/">Home</Link> {">"} Trauerfeier
          </div>
          <div className="funeral-hero-title">
            <h1>Trauerfeier</h1>
          </div>
          <div className="funeral-hero-button">
            <Link to="/">Anfragen →</Link>
          </div>
        </div>
      </div>
      <div className="funeral-container">
        <div className="funeral-page">
          <div className="funeral-hero-text">
            <h2>Trauerfeier</h2>
            <p>
              In diesen schweren Stunden des Abschieds bietet das Shiraz,
              gelegen in unmittelbarer Nähe zum Düsseldorfer Nordfriedhof, einen
              Ort der Ruhe und des Gedenkens. Wir verstehen, wie wichtig es ist,
              diesen Moment mit Würde und Respekt zu gestalten, und stehen Ihnen
              bei der Ausrichtung Ihres Trauercafés zur Seite. Unser Ziel ist
              es, Ihnen in dieser schwierigen Zeit beizustehen und einen Raum zu
              schaffen, in dem Sie sich in aller Ruhe von Ihrem geliebten
              Menschen verabschieden können.
            </p>
            <h3>Individuelle Gestalltung ganz nach ihre Wünschen</h3>
            <p>
              Wir richten Ihr Trauercafé ganz nach Ihren persönlichen Wünschen
              und Bedürfnissen aus. Uns ist bewusst, dass jeder Abschied
              einzigartig ist, und setzen alles daran, Ihre individuellen
              Vorstellungen zu erfüllen. Von sorgfältig ausgewählten
              Blumenarrangements über eine stilvolle Dekoration bis hin zu einer
              liebevoll zusammengestellten Verpflegung - wir kümmern uns um
              jedes Detail, um eine würdevolle und angemessene Atmosphäre zu
              schaffen.
            </p>
            <h3>Lassen Sie uns zusammen gestalten</h3>
            <p>
              Laden Sie Freunde und Familie ein, um in einem würdigen Rahmen
              Abschied zu nehmen und Erinnerungen zu teilen. Unser erfahrenes
              Team steht Ihnen von der Planung bis zur Durchführung mit Rat und
              Tat zur Seite. Wir bieten verschiedene Arrangements an, die
              flexibel an Ihre Wünsche angepasst werden können, um diesen Tag so
              persönlich und erinnerungswürdig wie möglich zu gestalten.
            </p>
            <h3>Kontaktieren Sie uns</h3>
            <p>
              Wir laden Sie herzlich ein, uns zu kontaktieren, um mehr über
              unsere Dienstleistungen zu erfahren und gemeinsam ein Trauercafé
              zu planen, das Ihren Erwartungen entspricht. Lassen Sie uns in
              dieser schweren Zeit für Sie da sein. Rufen Sie uns an oder
              schreiben Sie uns eine E-Mail, um einen Beratungstermin zu
              vereinbaren. Ihr Vertrauen ist uns wichtig, und wir sind hier, um
              Ihnen in jeder Hinsicht zur Seite zu stehen.
            </p>

            <p>
              Wir sind für Sie da- im Shiraz, in unmittelbarer Nähe zum
              Düsseldorfer NordFriedhof
            </p>
          </div>
          <div className="image-slider">
            {doubleImages.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Slide ${index + 1}`}
                className="slide-image"
              />
            ))}
          </div>
          <div className="funeral-anfragen-section">
            <h1>Anfragen</h1>
            <iframe
              src="https://www.cognitoforms.com/f/jNdX0G9liUemtCABkK626g/10"
              width="100%"
              height="693"
              border="0"
              class="styled-iframe"
            ></iframe>
            <script src="https://www.cognitoforms.com/f/iframe.js"></script>
          </div>
        </div>
      </div>
    </>
  );
}

export default Funeral;
